<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      tag="form"
    >
      <div
        v-if="loaderData"
        class="text-center mt-5"
      >
        <b-spinner
          type="grow"
          label="Loading..."
        />
      </div>
      <b-row v-else>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Contact Email"
            rules="required"
          >
            <b-form-group
              label="Contact Email"
              label-for="Contact Email"
            >
              <b-form-input
                id="Contact Email"
                v-model="SocialContactsForm.email"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Contact Email"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Instagram URL -->
          <validation-provider
            #default="validationContext"
            name="Instagram URL"
            rules="required"
          >
            <b-form-group
              label="Instagram URL"
              label-for="Instagram URL"
            >
              <b-form-input
                id="Instagram URL"
                v-model="SocialContactsForm.instagram"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Instagram URL"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Facebook URL -->
          <validation-provider
            #default="validationContext"
            name="Facebook URL"
            rules="required"
          >
            <b-form-group
              label="Facebook URL"
              label-for="Facebook URL"
            >
              <b-form-input
                id="Facebook URL"
                v-model="SocialContactsForm.facebook"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Facebook URL"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Address In English -->
          <validation-provider
            #default="validationContext"
            name="Address In English"
            rules="required"
          >
            <b-form-group
              label="Address In English"
              label-for="Address In English"
            >
              <b-form-input
                id="Address In English"
                v-model="SocialContactsForm.address_en"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Address In English"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Address In Arabic -->
          <validation-provider
            #default="validationContext"
            name="Address In Arabic"
            rules="required"
          >
            <b-form-group
              label="Address In Arabic"
              label-for="Address In Arabic"
            >
              <b-form-input
                id="Address In Arabic"
                v-model="SocialContactsForm.address_ar"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Address In Arabic"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader"
            variant="primary"
            class="mr-1"
            @click="addSocialContacts()"
          >
            Update
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'

export default {
  components: {
  },
  data() {
    return {
      id: this.$route.params.id,
      isVoucher: false,
      required,
      allSelected: [],
      indeterminate: false,
      countries: [],
      users: [],
      loader: false,
      loaderData: false,
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase
    const SocialContacts_images = ref([])

    const SocialContactsForm = ref({

    })
    return {
      getValidationState,
      SocialContactsForm,
      SocialContacts_images,
    }
  },
  mounted() {
    this.showSocialContacts()
    this.getCountries()
  },
  methods: {
    selectFile() {
      this.$refs.refInputEl.$el.click()
    },
    deleteImg(id) {
      axios.get(`delete_image/${id}`).then(res => {
        if (res.status === 200) {
          this.showSocialContacts()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Deleted Succesfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server Error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }).catch(() => {
        // this.formData = new FormData()
      }).finally(() => {
        this.loader = false
      })
    },

    showSocialContacts() {
      this.loaderData = true

      axios.get('social_contacts').then(res => {
        if (res.status === 200) {
          this.SocialContactsForm = res.data.data.social_contacts
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server Error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }).catch(() => {
        // this.formData = new FormData()
      }).finally(() => {
        this.loaderData = false
      })
    },
    addSocialContacts() {
      this.loader = true
      const formData = new FormData()

      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in this.SocialContactsForm) {
        formData.append(key, this.SocialContactsForm[key])
      }

      axios.post('update_social_contacts', formData).then(res => {
        if (res.status === 200 || res.status === 201) {
          this.$router.push({ name: 'social-contacts' })
          this.$toasted.show('Updated Successfully', {
            position: 'top-center',
            duration: 3000,
          })
        }
      }).catch(error => {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Somthing Went Wrong',
              icon: 'BellIcon',
              variant: 'error',
            },
          })
        }
      }).finally(() => {
        this.loader = false
      })
    },
  },
}

</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .deleteImgIcon{
    cursor: pointer;
    color: grey;
  }
  </style>
